import React from 'react';
import { graphql } from 'gatsby';
import Image from '@components/Image';
import MarkdownViewer from '@components/MarkdownViewer';
import isEmpty from 'lodash/isEmpty';
import Tag from 'antd/lib/tag';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import CardCarousel from '@components/CardCarousel';
import SocialSharing from '@components/SocialSharing';
import CardCategory from '@components/Cards/CardCategory';
import AuthorPagePreview from '@components/AuthorPagePreview';
import DesktopAds from '@components/ContentViewer/Advertisement/DesktopAds';
import MobileAds from '@components/ContentViewer/Advertisement/MobileAds';
import VideoFrame from '@components/VideoFrame';
import Audio from '@components/Audio';

import getAbsoluteURL from '@helpers/methods/getAbsoluteURL';
import formatPapuaDate from '@helpers/methods/formatZuluTime';

import selectAuthor from '@helpers/methods/modifyContents/utils/selectAuthor';
import selectAuthorLogoUrl from '@helpers/methods/modifyContents/utils/selectAuthorLogoUrl';
import selectAuthorProfileUrl from '@helpers/methods/modifyContents/utils/selectAuthorProfileUrl';
import selectContentImage from '@helpers/methods/modifyContents/utils/selectContentImage';

import getCareerAdviceCardsProps from '@helpers/methods/modifyContents/getCareerAdviceCardsProps';

const CareerAdvice = ({ data, location }) => {
  const previewAuthor = data?.strapiAuthorPages;

  let advice = data?.strapiCareerAdvices;
  advice = selectAuthor(advice, data?.strapiCareerAdvices);
  advice = selectAuthorLogoUrl(advice, data?.strapiCareerAdvices);
  advice = selectContentImage(advice, data?.strapiCareerAdvices);

  const relatedAdviceNodes = data?.allStrapiCareerAdvices?.nodes;
  const relatedAdvice = getCareerAdviceCardsProps(relatedAdviceNodes);

  const audioExt = advice?.audio?.ext;
  const audioAbsoluteURL = getAbsoluteURL(
    advice?.audio?.publicURL,
    advice?.audio?.url
  );

  const carouselStyle = relatedAdvice?.length === 1 && 'single-card-wrapper';

  const image = advice?.metaImage
    ? advice?.metaImage?.localFile?.childImageSharp?.resize
    : null;

  const sampleAdviceDate = formatPapuaDate(advice?.published_date);
  const hasNoSampleDate = !sampleAdviceDate;
  const actualAdviceDate = formatPapuaDate(advice?.published_at);
  const adviceDate = hasNoSampleDate ? actualAdviceDate : sampleAdviceDate;

  const coverImageUrl = advice?.cover_image?.url
    ? `${process.env.GATSBY_API_URL}${advice?.cover_image?.url}`
    : null;

  const thumbnailImageUrl = advice?.thumbnail_image?.url
    ? `${process.env.GATSBY_API_URL}${advice?.thumbnail_image?.url}`
    : null;

  return (
    <Layout path={location?.pathname}>
      <SEO
        title={advice?.title}
        metaImage={image}
        description="Career advice"
      />
      <div className="advice-page-section _section-padding">
        <div className="advice-page">
          <div className="content-and-side-ads-container">
            <div className="content-column">
              <div className="image-container">
                <Image
                  className="desktop-image"
                  image={
                    advice?.cover_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || coverImageUrl
                  }
                />
                <Image
                  className="phone-image"
                  image={
                    advice?.thumbnail_image?.localFile?.childImageSharp
                      ?.gatsbyImageData || thumbnailImageUrl
                  }
                />
              </div>
              <div className="header-wrapper">
                <CardCategory label={advice?.career_advice_category?.name} />
                <h1 className="advice-title">{advice?.title}</h1>
                <div className="author-info">
                  <div className="author">
                    by <span className="author-label">{advice?.author}</span>
                  </div>
                  <div className="_text_grayed_out">&middot;</div>
                  <div className="date _text_grayed_out">
                    <span>{adviceDate}</span>
                  </div>
                </div>
              </div>

              <Audio url={audioAbsoluteURL} ext={audioExt} />

              <MarkdownViewer
                className={'article-view'}
                source={advice?.content}
              />

              <VideoFrame url={advice?.video_url} />

              <SocialSharing path={location?.pathname} title={advice?.title} />

              <div className="tags-container">
                {advice?.tags?.map((tag) => (
                  <Tag className="tag-label" key={tag?.name}>
                    {tag?.name}
                  </Tag>
                ))}
              </div>
            </div>

            <DesktopAds />
          </div>

          {!!advice?.author ? (
            <>
              <AuthorPagePreview
                tagLine={''}
                authorName={advice?.author}
                logo={advice?.authorLogoUrl}
                profileLink={selectAuthorProfileUrl([advice])}
              />
            </>
          ) : (
            <>
              <AuthorPagePreview
                tagLine={previewAuthor?.company_tagline}
                authorName={previewAuthor?.company_name}
                logo={
                  previewAuthor?.avatar?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                profileLink={selectAuthorProfileUrl([previewAuthor])}
              />
            </>
          )}

          <MobileAds />

          {!isEmpty(relatedAdvice) ? (
            <div className="related-career-advice-container">
              <CardCarousel
                title="Related career advice"
                items={relatedAdvice}
                className={carouselStyle}
                linkRoot="/career-development/career-advice/"
              />
            </div>
          ) : (
            <div className="bottom-space" />
          )}
        </div>
      </div>
    </Layout>
  );
};

export default CareerAdvice;

export const query = graphql`
  query CareerAdviceQuery(
    $id: Int!
    $categoryId: Int
    $title: String
    $authorId: Int
  ) {
    strapiCareerAdvices(strapiId: { eq: $id }) {
      video_url
      strapiId
      title
      content
      created_at(formatString: "MMMM DD, YYYY")
      metaImage: image {
        localFile {
          childImageSharp {
            resize(width: 1200, height: 628, quality: 100) {
              src
              width
              height
            }
          }
        }
        url
      }

      cover_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      cover_image_old: image {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      thumbnail_image {
        source_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
        cropped_image {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }
      }
      thumbnail_image_old: image_thumbnail {
        localFile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 100)
          }
        }
        url
      }

      audio {
        publicURL
        url
        ext
      }

      creator_id
      created_by {
        username
      }

      career_advice_category {
        name
      }
      tags {
        name
      }
      published_date
      published_at
    }

    allStrapiCareerAdvices(
      limit: 6
      sort: { fields: published_date, order: DESC }
      filter: {
        title: { ne: $title }
        career_advice_category: { id: { eq: $categoryId } }
        content_status: { value: { eq: "2" } }
      }
    ) {
      nodes {
        strapiId
        slug
        title

        creator_id
        created_by {
          username
        }

        category: career_advice_category {
          name
        }
        created_at(formatString: "MMMM DD, YYYY")

        thumbnail_image {
          source_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
          cropped_image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100)
              }
            }
            url
          }
        }

        thumbnail_image_old: image_thumbnail {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
          url
        }

        published_date
        published_at
      }
    }

    strapiAuthorPages(created_by: { id: { eq: $authorId } }) {
      avatar {
        localFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              quality: 100
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
              aspectRatio: 1
            )
          }
        }
        url
      }
      company_name
      company_tagline
      slug
    }
  }
`;
